import { memo, ReactNode, CSSProperties } from 'react';
import styles from './card-scroller.module.css';

export type CardSlotProps = {
  isVisible: boolean;
  index: number;
  card: ReactNode;
};

export function CardSlot({ isVisible, index, card }: CardSlotProps) {
  return (
    <div
      className={`${styles.cardSlot} ${isVisible ? styles.isVisible : ''}`}
      style={{ '--card-slot--index': index + 1 } as CSSProperties}
    >
      {isVisible ? card : null}
    </div>
  );
}

export const CardSlotMemo = memo(CardSlot);
