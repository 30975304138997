import { memo } from 'react';
import { ButtonMemo } from '../../buttons/button/button';
import { ChevronLeftIcon } from '../../svg-icons/chevron-left-icon';
import { ChevronRightIcon } from '../../svg-icons/chevron-right-icon';
import styles from './card-scroller.module.css';

export type ScrollButtonProps = {
  direction: 'next' | 'prev';
  canScroll: boolean;
  scrollToNextBatch: () => void;
};

export function ScrollButton({ direction, canScroll, scrollToNextBatch }: ScrollButtonProps) {
  return (
    <div className={`${styles[direction]} ${canScroll ? styles.isActive : ''}`}>
      <ButtonMemo
        className={styles.scrollButton}
        theme="SecondaryDark"
        tabIndex={-1}
        disabled={!canScroll}
        onClick={scrollToNextBatch}
      >
        {direction === 'next' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </ButtonMemo>
    </div>
  );
}

export const ScrollButtonMemo = memo(ScrollButton);
