import { useMemo } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

type UseIsInViewOptions = Omit<IntersectionOptions, 'root'> & {
  root?: HTMLElement | Document | string | null;
};

// This hook is just a lightweight wrapper around `useInView` that allows you
// to pass a string selector for the root element.
export function useIsInView({ root, ...options }: UseIsInViewOptions = {}) {
  const rootElement = useMemo(() => {
    return typeof window === 'undefined'
      ? null
      : typeof root === 'string'
        ? document.querySelector(root)
        : root;
  }, [root]);

  return useInView({
    root: rootElement,
    ...options,
  });
}
