import { useRef } from 'react';

const NO_ARGS = Symbol();

type ThrottleState<T extends any[]> = {
  fn: (...args: T) => void;
  wait: number;
  leading: boolean;
  timeoutId?: number;
  lastArgs: T | typeof NO_ARGS;
  throttled?: {
    (...args: T): void;
    cancel(): void;
  };
};

export function useThrottle<T extends any[]>(
  fn: (...args: T) => void,
  wait: number,
  leading = false,
) {
  const stateRef = useRef<ThrottleState<T>>({
    fn,
    wait,
    leading,
    lastArgs: NO_ARGS,
    throttled: undefined,
  });

  // Keep the args in sync with the latest.
  stateRef.current.fn = fn;
  stateRef.current.wait = wait;
  stateRef.current.leading = leading;

  // Create the throttled function only once
  if (stateRef.current.throttled === undefined) {
    const throttled = (...args: T) => {
      stateRef.current.lastArgs = args;
      if (stateRef.current.timeoutId === undefined) {
        if (stateRef.current.leading) {
          stateRef.current.fn(...stateRef.current.lastArgs);
          stateRef.current.lastArgs = NO_ARGS;
        }
        stateRef.current.timeoutId = window.setTimeout(() => {
          if (stateRef.current.lastArgs !== NO_ARGS) {
            stateRef.current.fn(...stateRef.current.lastArgs);
            stateRef.current.lastArgs = NO_ARGS;
          }
          stateRef.current.timeoutId = undefined;
        }, stateRef.current.wait);
      }
    };

    throttled.cancel = () => {
      window.clearTimeout(stateRef.current.timeoutId);
      stateRef.current.timeoutId = undefined;
      stateRef.current.lastArgs = NO_ARGS;
    };

    stateRef.current.throttled = throttled;
  }

  return stateRef.current.throttled;
}
