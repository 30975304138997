import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

let cachedResizeObserver:
  | {
      observer: ResizeObserver;
      subscribe: (target: Element, callback: UseResizeObserverCallback) => void;
      unsubscribe: (target: Element, callback: UseResizeObserverCallback) => void;
    }
  | undefined;

export type UseResizeObserverCallback = (
  entry: ResizeObserverEntry,
  observer: ResizeObserver,
) => any;

export function useResizeObserver<T extends Element>(
  target: React.RefObject<T>,
  callback: UseResizeObserverCallback,
) {
  const callBackRef = useRef(callback);

  // Update the callback ref so we can use the latest callback.
  callBackRef.current = callback;

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    const targetEl = target.current;
    if (!targetEl) return;

    let didUnmount = false;
    const resizeObserver = getResizeObserver();
    const callback = (entry: ResizeObserverEntry, observer: ResizeObserver) => {
      if (didUnmount) return;
      callBackRef.current(entry, observer);
    };

    resizeObserver.subscribe(targetEl, callback);

    return () => {
      didUnmount = true;
      resizeObserver.unsubscribe(targetEl, callback);
    };
  }, [target]);
}

function getResizeObserver() {
  if (cachedResizeObserver) return cachedResizeObserver;

  const callbacks: Map<Element, Array<UseResizeObserverCallback>> = new Map();
  const observer = new ResizeObserver(
    (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      const triggered = new Set<Element>();
      for (const entry of entries) {
        if (triggered.has(entry.target)) continue;
        triggered.add(entry.target);
        callbacks.get(entry.target)?.forEach((cb) => cb(entry, observer));
      }
    },
  );
  const resizeObserver = {
    observer,
    subscribe(target: Element, callback: UseResizeObserverCallback) {
      const targetCallbacks = callbacks.get(target) ?? [];
      targetCallbacks.push(callback);
      callbacks.set(target, targetCallbacks);
      observer.observe(target);
    },
    unsubscribe(target: Element, callback: UseResizeObserverCallback) {
      const targetCallbacks = callbacks.get(target);
      if (!targetCallbacks) return;

      // If there is only one callback, we can unsubscribe from the observer.
      if (targetCallbacks.length === 1) {
        callbacks.delete(target);
        observer.unobserve(target);

        // Clean up the cached observer when no more subscriptions exist.
        if (callbacks.size === 0) {
          observer.disconnect();
          cachedResizeObserver = undefined;
        }

        return;
      }

      // Remove the callback from the list of callbacks.
      callbacks.set(
        target,
        targetCallbacks.filter((cb) => cb !== callback),
      );
    },
  };

  return (cachedResizeObserver = resizeObserver);
}
