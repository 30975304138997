import { useEffect, useMemo, useRef, useCallback, useState, RefObject } from 'react';
import { useResizeObserver } from './use-resize-observer';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
import { useThrottle } from '../hooks/use-throttle';
import { getStyle } from '../utils/get-style';
import { getStyleAsFloat } from '../utils/get-style-as-float';
import { getWidth } from '../utils/get-width';
import { getMaxScrollLeft } from '../utils/get-max-scroll-left';
import { ticker } from '../utils/ticker';

function useBatchScroll(scrollElementRef: RefObject<HTMLElement>) {
  const nextScrollLeftRef = useRef<number | null>(null);
  const setupTickIdRef = useRef(Symbol());
  const scrollEndTickIdRef = useRef(Symbol());

  const scrollEndCallback = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;

    ticker.once(
      'write',
      () => {
        if (scrollElem.isConnected) {
          scrollElem.removeAttribute('data-batch-scrolling');
        }
      },
      scrollEndTickIdRef.current,
    );
  }, [scrollElementRef]);

  const queueScroll = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;

    ticker.once(
      'write',
      () => {
        const nextScrollLeft = nextScrollLeftRef.current;
        if (nextScrollLeft === null) return;

        // Unbind scrollend tick.
        ticker.off('write', scrollEndTickIdRef.current);

        // Unbind existing scrollend listener.
        scrollElem.removeEventListener('scrollend', scrollEndCallback);

        // Set the batch scrolling attribute.
        scrollElem.setAttribute('data-batch-scrolling', 'true');

        // Add scroll end listener.
        scrollElem.addEventListener('scrollend', scrollEndCallback, { once: true });

        // Scroll to the next batch smoothly.
        scrollElem.scroll({
          left: nextScrollLeft,
          behavior: 'smooth',
        });
      },
      setupTickIdRef.current,
    );
  }, [scrollElementRef, scrollEndCallback]);

  // Scroll to the next batch.
  const scrollToNextBatch = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;

    ticker.once(
      'read',
      () => {
        const { scrollLeft } = scrollElem;
        const maxScrollLeft = getMaxScrollLeft(scrollElem);

        // If the element is scrolled to the end already, reset the next
        // scrollLeft value and return.
        if (Math.ceil(scrollLeft) === Math.floor(maxScrollLeft)) {
          nextScrollLeftRef.current = null;
          return;
        }

        // Compute the next scrollLeft value.
        nextScrollLeftRef.current = Math.min(
          scrollLeft + getWidth(scrollElem, 'content'),
          maxScrollLeft,
        );
      },
      setupTickIdRef.current,
    );

    queueScroll();
  }, [scrollElementRef, queueScroll]);

  const scrollToPrevBatch = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;

    ticker.once(
      'read',
      () => {
        const { scrollLeft } = scrollElem;

        // If the element is scrolled to the beginning already, reset the next
        // scrollLeft value and return.
        if (scrollLeft === 0) {
          nextScrollLeftRef.current = null;
          return;
        }

        // Compute the next scrollLeft value.
        nextScrollLeftRef.current = Math.max(scrollLeft - getWidth(scrollElem, 'content'), 0);
      },
      setupTickIdRef.current,
    );

    queueScroll();
  }, [scrollElementRef, queueScroll]);

  return useMemo(
    () => ({
      scrollToNextBatch,
      scrollToPrevBatch,
    }),
    [scrollToNextBatch, scrollToPrevBatch],
  );
}

function useScrollState({
  scrollElementRef,
  scrollContentRef,
}: {
  scrollElementRef: RefObject<HTMLElement>;
  scrollContentRef: RefObject<HTMLElement>;
}) {
  const tickIdRef = useRef(Symbol());
  const canScrollForwardRef = useRef(false);
  const canScrollBackRef = useRef(false);
  const prevCanScrollForwardRef = useRef(false);
  const prevCanScrollBackRef = useRef(false);
  const [canScrollForward, setCanScrollForward] = useState(false);
  const [canScrollBack, setCanScrollBack] = useState(false);
  const canScroll = canScrollBack || canScrollForward;

  const checkScrollState = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    const scrollContentElem = scrollContentRef.current;
    if (!scrollElem || !scrollContentElem) return;

    ticker.once(
      'read',
      () => {
        const { scrollLeft } = scrollElem;
        canScrollBackRef.current = scrollLeft > 0;
        canScrollForwardRef.current =
          Math.ceil(scrollLeft) <
          Math.floor(scrollElem.scrollWidth - getWidth(scrollElem, 'padding'));
      },
      tickIdRef.current,
    );

    ticker.once(
      'write',
      () => {
        if (canScrollBackRef.current !== prevCanScrollBackRef.current) {
          prevCanScrollBackRef.current = canScrollBackRef.current;
          setCanScrollBack(canScrollBackRef.current);
        }

        if (canScrollForwardRef.current !== prevCanScrollForwardRef.current) {
          prevCanScrollForwardRef.current = canScrollForwardRef.current;
          setCanScrollForward(canScrollForwardRef.current);
        }
      },
      tickIdRef.current,
    );
  }, [scrollElementRef, scrollContentRef]);

  const throttledCheckScrollState = useThrottle(checkScrollState, 100);

  // Initial check.
  useIsomorphicLayoutEffect(() => {
    checkScrollState();
  }, [checkScrollState]);

  // Check if the scroller can be scrolled back/forward on resize.
  useResizeObserver(scrollElementRef, throttledCheckScrollState);
  useResizeObserver(scrollContentRef, throttledCheckScrollState);

  // Check if the scroller can be scrolled back/forward on scroll.
  useEffect(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;

    // Check after each scroll.
    scrollElem.addEventListener('scroll', throttledCheckScrollState);

    return () => {
      scrollElem.removeEventListener('scroll', throttledCheckScrollState);
    };
  }, [throttledCheckScrollState]);

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      throttledCheckScrollState.cancel();
    };
  }, []);

  return useMemo(
    () => ({
      canScroll,
      canScrollBack,
      canScrollForward,
    }),
    [canScroll, canScrollBack, canScrollForward],
  );
}

function useScrollIndicator({
  scrollElementRef,
  scrollContentRef,
  scrollIndicatorThumbRef,
}: {
  scrollElementRef: RefObject<HTMLElement>;
  scrollContentRef: RefObject<HTMLElement>;
  scrollIndicatorThumbRef: RefObject<HTMLElement>;
}) {
  const thumbWidthRef = useRef(0);
  const prevThumbWidthRef = useRef(0);
  const thumbWidthCallbackIdRef = useRef(Symbol());
  const thumbPositionRef = useRef(0);
  const prevThumbPositionRef = useRef(0);
  const thumbPositionCallbackIdRef = useRef(Symbol());

  const updateThumbWidth = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    const indicatorThumbElem = scrollIndicatorThumbRef.current;
    if (!scrollElem || !indicatorThumbElem) return;

    ticker.once(
      'read',
      () => {
        thumbWidthRef.current = scrollElem.clientWidth / scrollElem.scrollWidth || 0;
      },
      thumbWidthCallbackIdRef.current,
    );

    ticker.once(
      'write',
      () => {
        if (thumbWidthRef.current !== prevThumbWidthRef.current) {
          prevThumbWidthRef.current = thumbWidthRef.current;
          scrollIndicatorThumbRef.current?.style.setProperty(
            '--card-scroller--indicator-scroll-factor',
            `${thumbWidthRef.current}`,
          );
        }
      },
      thumbWidthCallbackIdRef.current,
    );
  }, [scrollElementRef, scrollIndicatorThumbRef]);

  const updateThumbPosition = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    const indicatorThumbElem = scrollIndicatorThumbRef.current;
    if (!scrollElem || !indicatorThumbElem) return;

    ticker.once(
      'read',
      () => {
        thumbPositionRef.current =
          Math.ceil(scrollElem.scrollLeft) /
            Math.floor(scrollElem.scrollWidth - scrollElem.clientWidth) || 0;
      },
      thumbPositionCallbackIdRef.current,
    );

    ticker.once(
      'write',
      () => {
        if (thumbPositionRef.current !== prevThumbPositionRef.current) {
          prevThumbPositionRef.current = thumbPositionRef.current;
          scrollIndicatorThumbRef.current?.style.setProperty(
            '--card-scroller--indicator-thumb-position',
            `${thumbPositionRef.current}`,
          );
        }
      },
      thumbPositionCallbackIdRef.current,
    );
  }, [scrollElementRef, scrollIndicatorThumbRef]);

  // Apply initial sizing and position.
  useIsomorphicLayoutEffect(() => {
    updateThumbWidth();
    updateThumbPosition();
  }, [updateThumbWidth, updateThumbPosition]);

  // Update thumb width after each resize.
  useResizeObserver(scrollElementRef, updateThumbWidth);
  useResizeObserver(scrollContentRef, updateThumbWidth);

  // Update thumb position after each scroll.
  useEffect(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;
    scrollElem.addEventListener('scroll', updateThumbPosition);
    return () => {
      scrollElem.removeEventListener('scroll', updateThumbPosition);
    };
  }, [updateThumbPosition]);

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      ticker.off('read', thumbWidthCallbackIdRef.current);
      ticker.off('write', thumbWidthCallbackIdRef.current);
      ticker.off('read', thumbPositionCallbackIdRef.current);
      ticker.off('write', thumbPositionCallbackIdRef.current);
      scrollIndicatorThumbRef.current?.style.removeProperty(
        '--card-scroller--indicator-scroll-factor',
      );
      scrollIndicatorThumbRef.current?.style.removeProperty(
        '--card-scroller--indicator-thumb-position',
      );
    };
  }, [scrollIndicatorThumbRef]);
}

function useOverscanCount(scrollElementRef: RefObject<HTMLElement>) {
  const [overscanCount, setOverscanCount] = useState(1);

  const updateOverscanCount = useCallback(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem) return;
    const cardCount = getStyle(scrollElem).getPropertyValue('--card-scroller--card-count');
    setOverscanCount(Math.max(1, parseInt(cardCount, 10) || 1));
  }, [scrollElementRef]);

  const throttledUpdateOverscanCount = useThrottle(updateOverscanCount, 100);

  // Initial calculation.
  useIsomorphicLayoutEffect(() => {
    updateOverscanCount();
  }, [updateOverscanCount]);

  // Update overscan count on resize.
  useResizeObserver(scrollElementRef, throttledUpdateOverscanCount);

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      throttledUpdateOverscanCount.cancel();
    };
  }, []);

  return overscanCount;
}

function useSwipeScroll({
  scrollElementRef,
  canScroll,
  scrollToNextBatch,
  scrollToPrevBatch,
}: {
  scrollElementRef: RefObject<HTMLElement>;
  canScroll: boolean;
  scrollToNextBatch: () => void;
  scrollToPrevBatch: () => void;
}) {
  useEffect(() => {
    const scrollElem = scrollElementRef.current;
    if (!scrollElem || !canScroll) return;

    let attributeWriteTickId = Symbol();
    let attributeRemoveTimeout: number | undefined = undefined;
    let pointerId: number | null = null;
    let isSwipingX = false;
    let startTime = 0;
    let startX = 0;
    let startY = 0;

    const resetDrag = () => {
      window.removeEventListener('pointermove', onDragMove);
      window.removeEventListener('pointercancel', onDragEnd);
      window.removeEventListener('pointerup', onDragEnd);

      pointerId = null;
      isSwipingX = false;
      startTime = startX = startY = 0;
    };

    const onDragStart = (e: PointerEvent) => {
      if (pointerId === null && e.pointerType === 'mouse' && e.button === 0) {
        pointerId = e.pointerId;
        startTime = e.timeStamp;
        startX = e.clientX;
        startY = e.clientY;

        window.addEventListener('pointermove', onDragMove, { passive: true });
        window.addEventListener('pointercancel', onDragEnd, { passive: true });
        window.addEventListener('pointerup', onDragEnd, { passive: true });

        if (attributeRemoveTimeout !== undefined) {
          window.clearTimeout(attributeRemoveTimeout);
          attributeRemoveTimeout = undefined;
          ticker.once(
            'write',
            () => {
              scrollElem.removeAttribute('data-mouse-swipe');
            },
            attributeWriteTickId,
          );
        }
      }
    };

    const onDragMove = (e: PointerEvent) => {
      if (e.pointerId === pointerId) {
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        // Try to initiate swipe gesture.
        if (
          // Make sure swipe gesture has not yet been initiated.
          !isSwipingX &&
          // Make sure we have start time available.
          startTime &&
          // Make sure deltaX exceeds threshold.
          Math.abs(deltaX) > 20 &&
          // Make sure deltaX is larger than deltaY.
          Math.abs(deltaX) > Math.abs(deltaY) &&
          // Make sure the gesture does not exceed the time threshold.
          e.timeStamp - startTime < 300
        ) {
          isSwipingX = true;
          ticker.once(
            'write',
            () => {
              scrollElem.setAttribute('data-mouse-swipe', 'true');
              if (deltaX < 0) {
                scrollToNextBatch();
              } else {
                scrollToPrevBatch();
              }
            },
            attributeWriteTickId,
          );
        }
      }
    };

    const onDragEnd = (e: PointerEvent) => {
      if (e.pointerId === pointerId) {
        resetDrag();
        attributeRemoveTimeout = window.setTimeout(() => {
          attributeRemoveTimeout = undefined;
          ticker.once(
            'write',
            () => {
              scrollElem.removeAttribute('data-mouse-swipe');
            },
            attributeWriteTickId,
          );
        }, 50);
      }
    };

    // Listen to start event.
    scrollElem.addEventListener('pointerdown', onDragStart, { passive: true });

    return () => {
      resetDrag();
      scrollElem.removeEventListener('pointerdown', onDragStart);
      ticker.off('write', attributeWriteTickId);
      window.clearTimeout(attributeRemoveTimeout);
      attributeRemoveTimeout = undefined;
      scrollElem.removeAttribute('data-mouse-swipe');
    };
  }, [scrollElementRef, canScroll, scrollToNextBatch, scrollToPrevBatch]);
}

export function useVisibleRange({
  scrollElementRef,
  childrenCount,
  overscanCount,
  cardSize,
  cardOrientation,
}: {
  scrollElementRef: React.RefObject<HTMLElement>;
  childrenCount: number;
  overscanCount: number;
  cardSize: 'medium' | 'large';
  cardOrientation: 'landscape' | 'portrait';
}) {
  const [visibleRange, setVisibleRange] = useState({ start: -1, end: -1 });
  const visibleRangeRef = useRef({ ...visibleRange });
  const visibleNeedsUpdateRef = useRef(false);
  const cardWidthRef = useRef(0);
  const containerWidthRef = useRef(0);
  const cardGapRef = useRef(0);
  const resizeCheckIdRef = useRef(Symbol());
  const scrollCheckIdRef = useRef(Symbol());

  const computeDimensions = useCallback(() => {
    const scrollElement = scrollElementRef.current;
    if (!scrollElement) {
      cardWidthRef.current = 0;
      containerWidthRef.current = 0;
      cardGapRef.current = 0;
      return;
    }

    // Get the inner width of the container.
    containerWidthRef.current =
      scrollElement.getBoundingClientRect().width -
      getStyleAsFloat(scrollElement, 'paddingLeft') -
      getStyleAsFloat(scrollElement, 'paddingRight') -
      getStyleAsFloat(scrollElement, 'borderLeftWidth') -
      getStyleAsFloat(scrollElement, 'borderRightWidth');

    // Parse the card width from CSS variable.
    cardWidthRef.current =
      parseFloat(
        getStyle(document.documentElement).getPropertyValue(
          `--g--card-${cardOrientation}-${cardSize}-width-unitless`,
        ),
      ) || 0;

    // Parse the gap from CSS variable.
    cardGapRef.current =
      parseFloat(getStyle(document.documentElement).getPropertyValue('--g--card-gap')) || 0;
  }, [scrollElementRef, cardSize, cardOrientation]);

  const computeVisibleRange = useCallback(() => {
    const scrollElement = scrollElementRef.current;
    const containerWidth = containerWidthRef.current;
    const cardWidth = cardWidthRef.current;
    const cardGap = cardGapRef.current;

    if (!scrollElement || !containerWidth || !cardWidth) {
      return;
    }

    const scrollLeft = scrollElement.scrollLeft;
    const visibleStartIndex = Math.floor((scrollLeft + cardGap) / (cardWidth + cardGap));
    const visibleEndIndex = Math.ceil((scrollLeft + containerWidth) / (cardWidth + cardGap)) - 1;
    const start = Math.max(0, Math.min(visibleStartIndex - overscanCount, childrenCount - 1));
    const end = Math.min(Math.max(0, childrenCount - 1), visibleEndIndex + overscanCount);

    // Mark the visible range as needing update if it has changed.
    if (visibleRangeRef.current.start !== start || visibleRangeRef.current.end !== end) {
      visibleRangeRef.current = { start, end };
      visibleNeedsUpdateRef.current = true;
    }
  }, [childrenCount, overscanCount]);

  const updateVisibleRange = useCallback(() => {
    // Update the visible range if it has changed.
    if (visibleNeedsUpdateRef.current) {
      visibleNeedsUpdateRef.current = false;
      const { start, end } = visibleRangeRef.current;
      setVisibleRange({ start, end });
    }
  }, []);

  const handleResize = useCallback(() => {
    ticker.once(
      'read',
      () => {
        computeDimensions();
        computeVisibleRange();
      },
      resizeCheckIdRef.current,
    );
    ticker.once('write', updateVisibleRange, resizeCheckIdRef.current);
  }, [computeDimensions, computeVisibleRange, updateVisibleRange]);

  const throttledHandleResize = useThrottle(handleResize, 100);

  const handleScroll = useCallback(() => {
    ticker.once('read', computeVisibleRange, scrollCheckIdRef.current);
    ticker.once('write', updateVisibleRange, scrollCheckIdRef.current);
  }, [computeVisibleRange, updateVisibleRange]);

  // Initial computations.
  useIsomorphicLayoutEffect(() => {
    computeDimensions();
    computeVisibleRange();
    updateVisibleRange();
  }, [computeDimensions, computeVisibleRange, updateVisibleRange]);

  // Update visible range on resize.
  useResizeObserver(scrollElementRef, throttledHandleResize);

  // Update visible range on scroll.
  useEffect(() => {
    const scrollElement = scrollElementRef.current;
    if (!scrollElement) return;
    scrollElement.addEventListener('scroll', handleScroll);
    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, scrollElementRef]);

  // Cleanup on unmount.
  useEffect(() => {
    return () => {
      throttledHandleResize.cancel();
      ticker.off('read', scrollCheckIdRef.current);
      ticker.off('read', resizeCheckIdRef.current);
      ticker.off('write', resizeCheckIdRef.current);
    };
  }, []);

  return visibleRange;
}

export function useCardScroller({
  scrollElementRef,
  scrollContentRef,
  scrollIndicatorThumbRef,
  childrenCount,
  cardSize,
  cardOrientation,
}: {
  scrollElementRef: RefObject<HTMLElement>;
  scrollContentRef: RefObject<HTMLElement>;
  scrollIndicatorThumbRef: RefObject<HTMLElement>;
  childrenCount: number;
  cardSize: 'medium' | 'large';
  cardOrientation: 'landscape' | 'portrait';
}) {
  const { scrollToNextBatch, scrollToPrevBatch } = useBatchScroll(scrollElementRef);
  const { canScroll, canScrollBack, canScrollForward } = useScrollState({
    scrollElementRef,
    scrollContentRef,
  });
  const overscanCount = useOverscanCount(scrollElementRef);
  const visibleRange = useVisibleRange({
    scrollElementRef,
    childrenCount,
    overscanCount,
    cardSize,
    cardOrientation,
  });

  // Sync scroll indicator thumb size and position.
  useScrollIndicator({
    scrollElementRef,
    scrollContentRef,
    scrollIndicatorThumbRef,
  });

  // Scroll on mouse swipe.
  useSwipeScroll({
    scrollElementRef,
    canScroll,
    scrollToNextBatch,
    scrollToPrevBatch,
  });

  return useMemo(() => {
    return {
      scrollToPrevBatch,
      scrollToNextBatch,
      canScrollBack,
      canScrollForward,
      canScroll,
      visibleRange,
    };
  }, [
    scrollToPrevBatch,
    scrollToNextBatch,
    canScroll,
    canScrollBack,
    canScrollForward,
    visibleRange,
  ]);
}
